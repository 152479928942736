import React, { useRef, useState } from "react";
import "./Expert.css";
import { Player } from "@lottiefiles/react-lottie-player";
import Expertgif from "../../gif/contact us.json";
import responseImage from '../../images/gg_check-o.png';
import axios from "axios";
// import { BASEAPIENDPOINT } from "../../utils/common";

function Expert() {

  const [userData, SetUserData] = useState({ name: "", email: "", mobile: "",site:"XMS" })
  const [status, setstatus] = useState(false)
  const componentRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASEAPIENDPOINT}${process.env.REACT_APP_SITEID}/notify`, userData);
      if (response.status === 200) {
        // window.alert("Email Sent Successfully")
        setstatus(true)
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        SetUserData({ name: "", email: "", mobile: "" })
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div id="talkToExpert" className="backgroundright-image" ref={componentRef}>
        <div className="container expert-form d-block">
          <Player
            className={status ? "d-none expertplayer" : "d-block"}
            autoplay
            loop
            src={Expertgif}
            style={{ height: "50%", width: "50%" }}
          >
          </Player>
          <div className={status ? "d-none" : "d-block"}>
            <h1>Revolutionize Your Media Operations!</h1>
            <p>Talk with Experts, Schedule Demo - See Our Solutions in </p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName"
                  aria-describedby="nameHelp"
                  placeholder="Name"
                  onChange={(e) => SetUserData({ ...userData, name: e.currentTarget.value })}
                  required
                  value={userData.name}


                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  onChange={(e) => SetUserData({ ...userData, email: e.currentTarget.value })}
                  required
                  value={userData.email}

                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Mobile No"
                  onChange={(e) => SetUserData({ ...userData, mobile: e.currentTarget.value })}
                  required
                  value={userData.mobile}

                />
              </div>
              <button type="submit" className="btn btn-lg">
                Schedule a Demo
              </button>
            </form>
          </div>
          <div className={status ? "d-block response" : "d-none"}>
          <img src={responseImage} alt=""/>
          <h2>Submitted Successfully!</h2>
          <p>One of our experts will get in touch with you soon.</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Expert;
