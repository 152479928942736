import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import MainBody from "./components/mainBody/mainBody";
// import { BASEAPIENDPOINT, SITEID, PAGEID } from "./utils/common";

function App() {
  const [pageData, setPageData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEAPIENDPOINT}page/page/${process.env.REACT_APP_PAGEID}?siteId=${process.env.REACT_APP_SITEID}`
      );
      const data = response.data;
      setPageData(data.data.playlist);
    } catch (error) {
      console.error(error, "errormsg");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return <>{pageData && <MainBody data={pageData} />}</>;
}

export default App;
