import { useState, useEffect } from "react";
import axios from "axios";
// import { BASEAPIENDPOINT, SITEID } from "../../utils/common";

const useFetch = (props) => {
  const [data, setData] = useState([]);
  const [playlistData, setPlaylistData] = useState([]);

  const fetchData = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASEAPIENDPOINT}playlist/playlist/${id}?siteId=${process.env.REACT_APP_SITEID}`
      );

      setPlaylistData(res.data.data);
      const contentKeys = res.data.data.content.map((cont) => ({
        key: cont.key,
        contentType: cont.contentType,
      }));
      const articlePromises = contentKeys.map((i) =>
        axios.get(
          `${process.env.REACT_APP_BASEAPIENDPOINT}content/${i.contentType}/${i.key}?siteId=${process.env.REACT_APP_SITEID}`
        )
      );

      Promise.allSettled(articlePromises).then((results) => {
        const finalData = [];
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            const playlistData = { data: result.value.data.data };
            finalData.push(playlistData);
          }
        });
        setData(finalData);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(props.id);
  }, [props.id]);

  return {
    data,
    playlistData,
  };
};

export default useFetch;
